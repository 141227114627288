import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSignatures(ctx, params) {
      return useJwt.getSignatures(params).then(response => response);
    },
    createSignature(ctx, data) {
      return useJwt.createSignature(data).then(response => response);
    },
    updateSignature(ctx, data) {
      return useJwt.updateSignature(data).then(response => response);
    },
    deleteSignatures(ctx, data) {
      return useJwt.deleteSignatures(data).then(response => response);
    },
  },
};
