<template>
  <b-modal
    id="modal-signature"
    ref="refModal"
    :title="t('Chữ ký')"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            :label="t('Tên')"
            class="mt-2"
          >
            <b-form-input
              id="name"
              v-model="itemLocal.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-button
          v-b-modal.modal-signature-pad
          variant="warning"
          class="mb-50"
        >
          {{ t('Tạo chữ ký') }}
        </b-button>
        <image-view
          id="file-input-signature"
          v-model="itemLocal.signatureFile"
          :src="signature ? signature : itemLocal.signature"
          :label="t('Hình ảnh chữ ký')"
          :instruction="null"
        />

      </b-form>
    </validation-observer>
    <signature-pad @on-save-signature="onSaveSignature" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import ImageView from '@/views/components/ImageView.vue';
import useSignatureModal from './useSignatureModal';
import SignaturePad from '../pad/SignaturePad.vue';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    ImageView,
    BButton,
    SignaturePad,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSaveSignature,
      signature,
      onSubmit,
      t,
    } = useSignatureModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      signature,
      resetItemLocal,
      resetModal,
      onSubmit,
      onSaveSignature,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>
